import React from "react";
import { useState } from "react";

const HierachyNode = ({ node }) => {
  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState(node.name);

  const updateName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setOpen(false);
      sendRename().catch((err) => {
        alert(err);
        setName(node.name);
      }
      );
    }
  };

  const sendRename = () => {
    return fetch("https://categories.dev.pyrt.uk/api/v1/categories/rename", {
      method: "POST",
      body: JSON.stringify({
        id: node.id,
        newName: name,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((resp) => {
      let text = resp.json();
      if (resp.ok) {
        return text;
      } else {
        return text.then(Promise.reject.bind(Promise));
      }
    });
  };

  let thisTier = (
    <input
      autoFocus
      className="category_input"
      type="text"
      value={name}
      onChange={updateName}
      onBlur={() => setOpen(false)}
      onKeyUp={handleKeyUp}
    ></input>
  );

  if (!isOpen) thisTier = <p onClick={() => setOpen(true)}>{name}</p>;

  return (
    <li>
      {thisTier}
      <ul>
        {node.nodes.map((childNode) => (
          <HierachyNode key={childNode.name} node={childNode} />
        ))}
      </ul>
    </li>
  );
};

export default HierachyNode;
