import React from "react";
import { useState, useEffect } from 'react'
import HierachyNode from "./HierarchyNode";
import { v4 as idGen } from 'uuid';

const Hierarchy = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch('https://categories.dev.pyrt.uk/api/v1/categories')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCategories(data);
      })
  }, [])

  const [newCategory, setNewCategory] = useState("");
  const newCategoryChange = (e) => {
    setNewCategory(e.target.value);
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleAdd();
    }
  };

  const handleAdd = () => {

    const id = idGen();

    sendCreateCategory(id).then(() => {
      setNewCategory("")
      let newCat = {
        id: id,
        tier: 1,
        name: newCategory,
        nodes: []
      }
      setCategories([...categories, newCat]);
    }, (err) => {
      alert(err);
    }
    );
  }

  const sendCreateCategory = (id) => {
    return fetch("https://categories.dev.pyrt.uk/api/v1/categories/create", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        name: newCategory,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((resp) => {
      let text = resp.json();
      if (resp.ok) {
        return text;
      } else {        
        return text.then(Promise.reject.bind(Promise));
      }
    });

  }

  return (
    <div className="categories_section">
      <h1>Categories</h1>
      <div className="categories_section__create">
        <input type="text" onKeyUp={handleKeyUp} onChange={newCategoryChange} value={newCategory} /><button className="confirm" onClick={handleAdd}>Add +</button>
      </div>
      <ul>
        {categories.map((node) => (
          <HierachyNode key={node.name} node={node} />
        ))}
      </ul>
    </div>
  );
};

export default Hierarchy;
